
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

/* const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:230aa987-5b91-4aed-81b0-a33afd317eaa",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_aVsDiUsjW",
    "aws_user_pools_web_client_id": "io5m5jrk5qfn8hp62bq55kjp5",
    "oauth": {}
};
 */

/* const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:208d7e6a-58c0-4682-8b03-c98e9aee09eb",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_4VJ1ly7KY",
    "aws_user_pools_web_client_id": "5rvdbgjrsbkkuvjl7q06d0j2k2",
    "oauth": {}
};
*/

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:817e83ed-f4c1-45d6-8907-df1ae3aa5032",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_Yg5bJ1y3c",
    "aws_user_pools_web_client_id": "5e738dc706kssstdst8ksb7bn1",
    "oauth": {}
}



export default awsmobile;

import React, { Component } from 'react';
import { navigate } from '@reach/router'
import Moment from 'moment'

import { 
    Typography,
    Button,
    TextField,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    Grid,
    CircularProgress
 } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
 
import Alert from '../Alert'

import { API_BASE_URL, taskCategoriesList, taskWebsitesList, taskCountriesList, taskProjectsList } from '../../helpers/functions';

import NumberFormatCustom from "../../helpers/NumberFormatCustom"


export default class WorklogForm extends Component {

    static defaultProps = {
        data: {
            id: ``,
            task_title: ``,
            task_description: ``,
            worklog_date: new Date(),
            time_spent: ``, 
            worktype_id: ``,
            website_id: ``,
            country_id: ``,
            user_id: ``, 
            project_name: ``,
        }
      }

    constructor(props) {
        super(props);
        // console.log("Props received in Work log form: ",this.props)
        const propsData = this.props.data
        this.state = {
            id: propsData.id,
            task_title: propsData.task_title,
            task_description: propsData.task_description,
            worklog_date: propsData.worklog_date? propsData.worklog_date: Moment(new Date()).format("YYYY-MM-DD"),
            time_spent: propsData.time_spent, 
            worktype_id: propsData.worktype_id, 
            website_id: propsData.website_id,
            country_id: propsData.country_id,
            user_id: propsData.user_id?propsData.user_id:this.props.user_id,
            project_name: propsData.project_name,
            error: '',
            loading: false,            
          }

        this.handleChange = this.handleChange.bind(this);
        this.handleTaskDateChange = this.handleTaskDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange = event => {
			// console.log("Event here: ",event)
       if(event.target.name==="time_spent"){
            var unformatedTime = event.target.value;
            const formatedTime = unformatedTime.slice(0, 2) + ":" + unformatedTime.slice(2);
            // console.log("formatedTime: ",formatedTime);
            this.setState({
                [event.target.name]: formatedTime,
                error: '',
            })
       }else if(event.target.name==='worktype_id' && event.target.value != '3' ){
        this.setState({
          [event.target.name]: event.target.value,
          error: '',
          project_name:'',
        })
       }else {
            this.setState({
                [event.target.name]: event.target.value,
                error: '',
            })
       }        
    }

    handleTaskDateChange = date => {
        // console.log("Date received from form before set state converted",Moment(date).format('YYYY-MM-DD'))
        const formatedDate = Moment(date).format('YYYY-MM-DD')
        this.setState({
            worklog_date: formatedDate
        })
    }

    async handleSubmit(evt) {
        evt.preventDefault();
        this.setState({ loading: true })
        if(!this.state.worktype_id) {
          return this.setState({ loading: false, error: `Please select Category!` })
        }
        if(!this.state.country_id) {
          return this.setState({ loading: false, error: `Please select Country!` })
        }
        const requestOptions = {
            method: (this.state.id) ? 'PUT' : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({...this.state})
          };
        //   console.log(this.state.id? (" Final Data to API on Update: "): ("Final Data to API on Add: "),this.state);
          const API_URL = (this.state.id) ? `${API_BASE_URL}worklogs/${this.state.id}` : `${API_BASE_URL}worklogs`
          fetch(API_URL, requestOptions)
          .then(response => response.json())
          .then(data => {
            this.setState({ loading: false })
            navigate("/dashboard", { state: {success: true }})
          })
          .catch((error) => {
            this.setState({ loading: false, error: error.message })
          });
    }


    render() {  

        let { classes, cancelAction } = { ...this.props }
        //console.log(this.state)
        return (
            <>
            <Typography component="h1" variant="h5">
            Add Work Log
            </Typography>
            {this.state.error && (
                <Alert severity="error">{this.state.error}</Alert>
            )}
            <form className={classes.form} onSubmit={this.handleSubmit} >
               
                
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="task_title"
                    label="Task Title"
                    name="task_title"
                    autoFocus
                    onChange={this.handleChange}
                    value={this.state.task_title?this.state.task_title:""}
                />
               
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="task_description"
                    label="Task Description"
                    name="task_description"
                    onChange={this.handleChange}
                    value={this.state.task_description?this.state.task_description:""}
                />
								<Grid container spacing={1}>
									<Grid item xs={12} sm={4}>
											<FormControl className={classes.formControl} required>
											<InputLabel id="category-label">Category</InputLabel>
											<Select
											labelId="category-label"
											id="worktype_id"
											name="worktype_id"											
											value={this.state.worktype_id?this.state.worktype_id:''}
											onChange={this.handleChange}
											>
													{Object.entries(taskCategoriesList).map((category, index) => (
															<MenuItem value={category[0]} key={index}>{category[1]}</MenuItem>
													))}

											</Select>
											</FormControl>
									</Grid>
                  {this.state.worktype_id === "3" &&
                    <Grid item xs={12} sm={4} >
                        <FormControl className={classes.formControl}>
                        <InputLabel id="project-label">Project</InputLabel>
                        <Select
                        labelId="project-label"
                        id="project_name"
                        name="project_name"
                        value={this.state.project_name ? this.state.project_name : ''}
                        onChange={this.handleChange}
												required
                        >
                            {Object.entries(taskProjectsList).map((website, index) => (
                                <MenuItem value={website[1]} key={index}>{website[1]}</MenuItem>
                            ))}

                        </Select>
                        </FormControl>
                    </Grid>
                  }
									<Grid item xs={12} sm={4} >
											<FormControl className={classes.formControl}>
											<InputLabel id="website-label">Website</InputLabel>
											<Select
											labelId="website-label"
											id="website_id"
											name="website_id"
											value={this.state.website_id?this.state.website_id:''}
											onChange={this.handleChange}
											>
													{Object.entries(taskWebsitesList).map((website, index) => (
															<MenuItem value={website[0]} key={index}>{website[1]}</MenuItem>
													))}

											</Select>
											</FormControl>
									</Grid>
								</Grid>
								<Grid container spacing={1}>
									<Grid item xs={12} sm={4}>
											<FormControl className={classes.formControl} required>
											<InputLabel id="country-label">Country</InputLabel>
											<Select
											labelId="country-label"
											id="country_id"
											name="country_id"
											required
											value={this.state.country_id?this.state.country_id:''}
											onChange={this.handleChange}
											>
													{Object.entries(taskCountriesList).map((country, index) => (
															<MenuItem value={country[0]} key={index}>{country[1]}</MenuItem>
													))}

											</Select>
											</FormControl>
									</Grid>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Grid item xs={12} sm={4} >
											<KeyboardDatePicker 
													id="worklog_date"
													label="Task Date"
													variant="outlined"
													margin="normal"
													required
													format="dd/MM/yyyy"
                          disableFuture
													value={this.state.worklog_date}
													onChange={this.handleTaskDateChange}
													KeyboardButtonProps={{
															'aria-label': 'change date',
													}}
													/>
									</Grid>
									</MuiPickersUtilsProvider>
                  <Grid item xs={12} sm={4}>             
										<TextField
												variant="outlined"
												margin="normal"
												required
												id="time_spent"
												label="Time Spent"
												name="time_spent"
												onChange={this.handleChange}
												value={this.state.time_spent}
												InputProps={{
													inputComponent: NumberFormatCustom,
												}}
										/>
									</Grid> 
								</Grid>

                <Grid container spacing={1}>
									
									<Grid item xs={12} align="center">
										<Button
												type="submit"
												variant="contained"
												color="primary"
												size="large"
												className={classes.submit}
												disabled={this.state.loading}

										>
												Save  
											{this.state.loading && (
													<CircularProgress size={24} className={classes.buttonProgress} />
											)}
										</Button>
										<Button
											type="button"                    
											variant="contained"
											color="secondary"
											size="large"
											className={classes.submit}
											onClick={() => { navigate('/dashboard')} }
										>
											Cancel
										</Button>
									</Grid> 
								</Grid>
                
            </form>
            </>
        );
        
    }
}
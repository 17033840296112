import React from 'react'
import { Router } from '@reach/router'
import SignIn from '../components/Pages/SignIn'
import ForgotPassword from '../components/Pages/ForgotPassword'
import Profile from '../components/Pages/Profile'
import Users from '../components/Pages/Users'
import Dashboard from '../components/Pages/Dashboard'
import Worklog from '../components/Pages/Worklog'
import PrivateRoute from '../components/Routes/PrivateRoute'
import PublicRoute from '../components/Routes/PublicRoute'
import Amplify from 'aws-amplify'
import config from '../aws-exports'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#eb010f',
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      contrastText: '#ffcc00',
    },

    tonalOffset: 0.2,
    
  },
});



const App = () => {
  Amplify.configure(config)
  
  return (
    <ThemeProvider theme={theme}>
    <Router>
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/worklog" component={Worklog} />
      <PrivateRoute path="/my-profile" component={Profile} />
      <PrivateRoute path="/users" component={Users} />

      <PublicRoute path="/signin" component={SignIn} />
      <PublicRoute path="/forgot-password" component={ForgotPassword} />      
      <PublicRoute path="/" component={SignIn} />
    </Router>
    </ThemeProvider>
  )
}

export default App
import React, { useState, useEffect } from "react"
import MaterialTable from "material-table"
import { forwardRef } from "react"
import { Auth } from 'aws-amplify'
import { 
  API_BASE_URL, 
  userRolesList, 
  userGroupsList, 
  userStatusList 
} from "../../helpers/functions"

import { getCurrentUser } from '../Auth/AppUser'
import Alert from '../Alert'

import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  ChevronRight,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronLeft,
  Search,
  ArrowDownward,
  Remove,
  ViewColumn,
} from "@material-ui/icons"

const shadow = {
  boxShadow: 'none',
};

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}


export default function Users() {
  // const [userTableData, setUserTableData] = useState([])
  const user = getCurrentUser();
  const [currentUserRole, setcurrentUserRole] = useState(user['custom:user_role']);
  const [isAdmin, setIsAdmin] = useState(false);
  // const [userID, setuserID] = useState(null);
  const [state, setState] = useState({
    columns: [
      { title: "First Name", field: "first_name" },
      { title: "Last Name", field: "last_name" },
      { title: "Role", field: "role", lookup: userRolesList },
      { title: "Email", field: "email" },
      { title: "Group", field: "group", lookup: userGroupsList },
      { title: "Status", field: "status", lookup: userStatusList },
    ],
    data: [],
  })

  useEffect(() => {
    // checking against hard coded admin user ID's
    if(currentUserRole === "admin"){
    setIsAdmin(true)
    fetch(`${API_BASE_URL}users`)
      .then(response => response.json()) // parse JSON from request
      .then(data => {
        // console.log("Complete Users: ",data)
        setState(prevState => {
          return { ...prevState, data: data }
        })
      })}
      else {
        // do nothing in useeffect
      }
  }, [])

  return (<>
  {isAdmin ? (
    <MaterialTable 
    style={shadow}
      title="Manage Users"
      columns={state.columns}
      data={state.data}
      icons={tableIcons}
      options={{
        actionsColumnIndex: -1,
      }}
      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            setTimeout(async() => {
              console.log("newData: ",newData);
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({...newData, department: 'technical'})
              };

              try {
                const {firstname, lastname, username, email, password, userrole} = {firstname: newData.first_name, lastname: newData.last_name, username: newData.email, email: newData.email, password: "Password@123", userrole:newData.role};     

                fetch(`${API_BASE_URL}users`, requestOptions)
                .then(response => response.json())
                .then(data => {
                console.log("data inside fetch",data)
                const awsUserId = data.user_id; //taking user_id from API response message
                  setState(prevState => {
                    const data = [...prevState.data]
                    data.push(newData)
                    return { ...prevState, data }
                  });

                  Auth.signUp({
                    username,
                    password,
                    attributes: {
                        email: email,
                        name: firstname + " " + lastname,
                        given_name: firstname,
                        family_name: lastname,
                        'custom:user_id': awsUserId,
                        'custom:user_role': userrole,
                    },
                  })
                  .then(res=>console.log("AWS response", res))
                  .catch(err => console.log('There was an error:' + err.message));
                })
                // console.log("Adding user is successful, check email for verification code");
              } catch (error) {
                // console.log("Error during adding user into cognito: "+error.messag);
              }
              resolve() 
            }, 600)
          }),

        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {

              const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({...newData})
              };
              
              fetch(`${API_BASE_URL}users/${newData.id}`, requestOptions)
              .then(response => response.json())
              .then(data => {
                // console.log(data)
                setState(prevState => {
                  const data = [...prevState.data]
                  data[data.indexOf(oldData)] = newData
                  return { ...prevState, data }
                })
              })

              resolve()

            }, 600)
          }),

        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              
              const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
              };
              
              fetch(`${API_BASE_URL}users/${oldData.id}`, requestOptions)
              .then(response => response.json())
              .then(data => {
                // console.log(data)
                setState(prevState => {
                  const data = [...prevState.data]
                  data.splice(data.indexOf(oldData), 1)
                  return { ...prevState, data }
                })
              })
              .catch((error) => {
                alert("There was an error occured during deletion");
              });

              resolve()

            }, 600)
          }),
      }}
    />) : (
      <div>
        <Alert severity="warning">
          You are not permitted to acces this page
        </Alert>
    </div> 
    )}
    </>
  )
}

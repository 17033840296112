import React from 'react';
import { navigate } from '@reach/router'
import { Auth } from 'aws-amplify'
import { AppUser } from '../Auth'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

import { 
    List,
    ListItem,
    ListItemIcon,
    ListItemText
 } from '@material-ui/core';

 import { 
    Dashboard as DashboardIcon
} from '@material-ui/icons';


const { logout } = AppUser
function logOut(e) {
    e.preventDefault()

    Auth.signOut()
        .then(logout(() => navigate('/signin')))
        .catch(err => 
            console.log('error: ', err)
            )
}

const UserNav = (user) => {
    // console.log("logedin user detail inside side drawer", user.userDetails['custom:user_id'])
    return (
        <List>
            <ListItem button  onClick={e => navigate('/dashboard')}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>
            { (user.userDetails['custom:user_role'] === "admin") ?(
                <ListItem button  onClick={e => navigate('/users')}>
                    <ListItemIcon>
                        <PeopleAltIcon />
                    </ListItemIcon>                        
                    <ListItemText primary="Users" />
                </ListItem>) : null
            }
        </List>
    );
};

export default UserNav;
import React, { Component } from 'react';
import { Link, navigate } from '@reach/router'
import Box from '@material-ui/core/Box';
import {
    Typography,
    Button,
    TextField,
    IconButton,
    InputAdornment,
    Grid,
    CircularProgress,
 } from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Alert from '../Alert'

import { Auth } from 'aws-amplify'

export default class ResetPasswordForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: ``,
            auth_code: ``,
            password: ``,
            error: ``,
            showPassword: false,
            loading: false,
            stage: 0,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleSendVerificationCode = this.handleSendVerificationCode.bind(this);
        this.handleResetPassword = this.handleResetPassword.bind(this);
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            error: '',
        })
    }

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    async handleSendVerificationCode(evt) {
        evt.preventDefault();
        const { email } = this.state
        try {
            this.setState({ loading: true })
            //console.log(email)
            await Auth.forgotPassword(email)
            this.setState({ loading: false, stage: 1 })
        } catch (error) {
            this.setState({ loading: false, error: error.message })
        }
    }

    async handleResetPassword(evt) {
        evt.preventDefault();
        this.setState({ loading: true })
        const { email, auth_code, password } = this.state
        
        Auth.forgotPasswordSubmit(email, auth_code, password)
            .then(data => {
                //console.log(data)
                this.setState({ loading: false })
            })
            .then(() => navigate('/login'))
            .catch(error => {
                this.setState({ loading: false, error: error.message })
            })
    }


    render() {
        let { classes } = { ...this.props }

        if (this.state.stage === 0) {
            return (
                <>
                <Typography component="h1" variant="h5"  className={classes.title}>
                    Forgot Password?
                </Typography>
                {this.state.error && (
                    <Alert severity="error">{this.state.error}</Alert>
                )}
                <form className={classes.form} onSubmit={this.handleSendVerificationCode} >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type="email"
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={this.handleChange}
                        value={this.state.email}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={this.state.loading}

                    >
                        Send Verification Code
                        {this.state.loading && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                )}
            </Button>

            <Box className={classes.backButton}>
        <Link to="/signin" variant="body2">
          <IconButton aria-label="delete" className={classes.margin} size="large">
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
        </Link>
        </Box>
                    

                </form>
                </>
            );
        }

        return (
            <>
            <Typography component="h1" variant="h5">
                Forgot Password?
            </Typography>
            {this.state.error && (
                <Alert severity="error">{this.state.error}</Alert>
            )}
            <form className={classes.form} onSubmit={this.handleResetPassword} >
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    type="email"
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"                    
                    onChange={this.handleChange}
                    value={this.state.email}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="auth_code"
                    label="Verification Code"
                    name="auth_code"
                    onChange={this.handleChange}
                    value={this.state.auth_code}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    onChange={this.handleChange}
                    value={this.state.password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                            >
                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={this.state.loading}
                >
                    Reset Password
                    {this.state.loading && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                )}
            </Button>
            {/* <Grid container>
                    <Grid item xs align="center"> 
                        <Link to="/forgot-password" variant="body2">
                            Didn't receive the verfication code?
              </Link>
                    </Grid>
                    
                </Grid>
 */}
            </form>
            </>
        )
    }
}
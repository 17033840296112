const API_BASE_URL = 'http://gscworklogapi.morganmckinley.com/';
const userRolesList = {
    user: "User",
    admin: "Administrator"
};

// const departmentsList = {
//     technical: "Technical Services",
//     business: "Business Services",
//     finance: "Financial Services",
// };

const userGroupsList = {
    web: "Web Services",
    qa: "QA Services",
    design: "Design Services",
    seo: "SEO Services",
    support: "IT Support"
};

const userStatusList = {
    0: "Inactive",
    1: "Active"
};

const taskCategoriesList = {
    1: "Ask GSC",
    2: "Web Tasks",
    3: "Web Project",
    4: "SEO",
    5: "Graphic Design",
    6: "SysOps - Websites / Server Administration",
    7: "L&D (GDPR, Privacy)",
    8: "Meetings",
    9: "Training",
    10: "GSC HR",
    11: "Work Updates",
    12: "Team Management",
    13: "Network Downtime",
    14: "System Issues / Updates",
    15: "IT Support",
    16: "Starters / Leavers",
    17: "Job Posting",
    18: "CP / JO",
    19: "Qualtrics",
    20: "Marketo",
};

const taskCountriesList = {
    1: "Ireland Offices - IRL", 
    2: "UK Offices - UK",
    3: "Toronto - CA",
    4: "Sydney - AU",
    5: "Singapore - SG",
    6: "Hong Kong - HK",
    7: "Tokyo - JP",
    8: "Shanghai - CN",
    9: "Global",
    10: "HUB",
    11: "HR",
    12: "Accreate",
    13: "GSC",
    14: "GSC - HR",
    15: "PMO",
    16: "GDPR",
    17: "M3S - IE",
    18: "M3S - UK",
    19: "M3S - CN",
    20: "Other",
}

const taskWebsitesList = {
    1: "morganmckinley.com", 
    2: "morganmckinley.ie", 
    3: "morganmckinley.co.uk",   
    4: "morganmckinley.ca", 
    5: "morganmckinley.com.au", 
    6: "morganmckinley.com.sg", 
    7: "morganmckinley.com.hk", 
    8: "morganmckinley.co.jp", 
    9: "morganmckinley.com.cn", 
    10: "jobs.morganmckinley.com", 
    11: "m3ssolutions.com", 
    12: "m3s-consulting.co.uk", 
    13: "m3ssolutions.com.cn", 
    14: "jobs.morganmckinley.com.cn",    
    15: "careers.morganmckinley.com", 
    16: "accreate.com", 
    17: "lacreme.ie",  
    18: "Candidate Portal",  
    19: "Galaxy", 
    20: "PMO",              
    21: "blogs.morganmckinley.com", 
    22: "morgan.works",             
    23: "Other",
    24: "thisisorg.com",
}

const taskProjectsList = {
    1: ".COM Web Tasks",
    2: "Org Group Website",
    3: "GSC WorkLog Application",
    4: "2024 Salary Guide",
    5: "Jobseekers",
    6: ".COM Website - Rebranding",
    7: "MMK Inventory",
    8: "Galaxy - Singapore",
    9: "Thisisorg Website - Phase 2",
   10: "Employee Register",
   11: "PMO Portal",
   12: "Candidate Portal",
   13: "Abtran Work (BCP2024 Email Process)",
};

export { 
    API_BASE_URL, 
    userRolesList, 
    userGroupsList, 
    userStatusList, 
    taskCategoriesList, 
    taskCountriesList, 
    taskWebsitesList,
    taskProjectsList
}
import React, {useState} from 'react';
import { navigate } from '@reach/router'
import { Auth } from 'aws-amplify'
import { AppUser } from '../Auth'

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';

import { 
    Drawer,
    Typography,
    AppBar,
    Toolbar,
    Divider,
    Avatar,
    IconButton,
    useMediaQuery

 } from '@material-ui/core';

 import { 
    Menu as MenuIcon,
		ChevronLeft as ChevronLeftIcon,
		ExitToApp as ExitToAppIcon,
		Person as PersonIcon,
} from '@material-ui/icons';

import { getCurrentUser } from '../Auth/AppUser'

import clsx from 'clsx';

import UserNav from './UserNav';

import Logo from "../../images/logo.svg"

import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import {
	ListItemIcon,
	ListItemText
} from '@material-ui/core';

const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar
    },
    menuIcon: {
        color: 'black'
    },
    appBar: {
        background: '#ffff',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
				flexGrow: 1,
				color: 'black',
    },
    welcome_text: {
        paddingRight: theme.spacing(2)
    }, 
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    logo:{
        [theme.breakpoints.down('sm')]: {
            height:'36px',
          },
      },
      logoWrap:{
        flexGrow:'1',
        textAlign:'left',
      },
      logo:{
        height:'36px',
      },
      welcome_text:{
          color:'#000',
          fontSize:'14px',
          [theme.breakpoints.down('sm')]: {
            display:'none',
          },
      },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
      }, 
      toolbar: theme.mixins.toolbar,
     drawerPaper: {
    width: drawerWidth,
  },
}));

const UserLayoutHeader = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open2 = Boolean(anchorEl);
	
	const { logout } = AppUser
	const logOut = (e) => {
		e.preventDefault()
		Auth.signOut()
			.then(logout(() => navigate('/signin')))
			.catch(err => 
					console.log('error: ', err)
					)
	}

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const user = getCurrentUser()
    // console.log("Current user details: ",user);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <>
        	<AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
							<IconButton
									edge="start"
									color="inherit"
									aria-label="open drawer"
									onClick={handleDrawerOpen}
									className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
							>
              	<MenuIcon className={classes.menuIcon} />
              </IconButton>

              <div className={classes.logoWrap}><img src={Logo} alt="" /></div>
							
						{/* <Typography variant="h6" className={classes.title}>
							MMK
						</Typography> */}
            <div>
            <Typography component="span" noWrap className={classes.welcome_text}>
                          Welcome, {user.name}!
  </Typography>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="primary"
              >
               <Avatar className={classes.orange}>{user.given_name.charAt(0)}{user.family_name.charAt(0)}</Avatar>
              </IconButton>
              <Menu
                style={{ top: "40px" }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open2}
                onClose={handleClose}
              >
                <MenuItem onClick={e => navigate('/my-profile')} fontSize="large">
									<ListItemIcon>
											<PersonIcon />
									</ListItemIcon>                        
									<ListItemText primary="Profile"/>
								</MenuItem>
                <MenuItem onClick={e => logOut(e)}>
									<ListItemIcon>
                    <ExitToAppIcon />
                	</ListItemIcon>
                	<ListItemText primary="Signout" />
								</MenuItem>
              </Menu>
            </div>
            </Toolbar>
          </AppBar>

					<Drawer
						variant={isMobile === true ? 'persistent' : 'permanent'}
						classes={{
								paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
						}}
						open={open}
					>
						<div className={classes.toolbarIcon}>
								<IconButton onClick={handleDrawerClose} >
										<ChevronLeftIcon />
								</IconButton>
						</div>
						<Divider />
						<UserNav userDetails={user}/>
					</Drawer>
      </>
    );
};

export default UserLayoutHeader;
import React, { Component } from "react"
import { Button, TextField, Grid, CircularProgress } from "@material-ui/core"

import Alert from "../Alert"

import { Auth } from "aws-amplify"

export default class ProfileForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      error: "",
      loading: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getUserProfileInfo = this.getUserProfileInfo.bind(this)
  }

  getUserProfileInfo() {
    //const user = getCurrentUser()
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(user => {
        // console.log("Profile current user details", user)
        this.setState({
          firstname: user.attributes.given_name,
          lastname: user.attributes.family_name,
          email: user.attributes.email,
        })
        //console.log(user)
      })
      .catch(err => console.log(err))
  }

  componentDidMount() {
    this.getUserProfileInfo()
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      error: "",
    })
  }

  async handleSubmit(evt) {
    evt.preventDefault()
    try {
      this.setState({ loading: true })
      const { firstname, lastname } = this.state

      let user = await Auth.currentAuthenticatedUser()

      let result = await Auth.updateUserAttributes(user, {
        name: firstname,
        family_name: lastname,
      })
      //console.log(result); // SUCCESS
      this.setState({ loading: false })
      alert("Profile updated!")

      //navigate(`/signup-success`)
    } catch (error) {
      this.setState({ loading: false, error: error.message })
    }
  }

  render() {
    let { classes } = { ...this.props }

    //console.log(user)

    return (
      <form className={classes.form} onSubmit={this.handleSubmit}>
        {this.state.error && <Alert severity="error">{this.state.error}</Alert>}
        {this.state.firstname ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  name="firstname"
                  autoFocus
                  onChange={this.handleChange}
                  value={this.state.firstname}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  onChange={this.handleChange}
                  value={this.state.lastname}
                />
              </Grid>
            </Grid>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="email"
              id="email"
              label="Email Address"
              name="email"
              disabled={true}
              value={this.state.email}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={this.state.loading}
            >
              Save
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </>
        ) : (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        )}
      </form>
    )
  }
}

import React, { Component } from "react"

import {
  Button,
  TextField,
  IconButton,
  InputAdornment,
  Grid,
  CircularProgress,
} from "@material-ui/core"

import { Visibility, VisibilityOff } from "@material-ui/icons"

import Alert from "../Alert"

import { Auth } from "aws-amplify"

export default class UpdatePasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentpassword: ``,
      newpassword: "",
      showCurrentPassword: false,
      showNewPassword: false,
      error: "",
      loading: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleClickShowCurrentPassword = this.handleClickShowCurrentPassword.bind(
      this
    )
    this.handleClickShowNewPassword = this.handleClickShowNewPassword.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      error: "",
    })
  }

  handleClickShowCurrentPassword = () => {
    this.setState({ showCurrentPassword: !this.state.showCurrentPassword })
  }

  handleClickShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword })
  }

  async handleSubmit(evt) {
    evt.preventDefault()
    const { currentpassword, newpassword } = this.state
    this.setState({ loading: true })

    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, currentpassword, newpassword)
      })
      .then(data => {
        //console.log(data)
        this.setState({ loading: false })
        alert("Password updated!")
        //navigate('/login')
      })
      .catch(error => {
        this.setState({ loading: false, error: error.message })
      })
  }

  render() {
    let { classes } = { ...this.props }

    return (
      <form className={classes.form} onSubmit={this.handleSubmit}>
        {this.state.error && <Alert severity="error">{this.state.error}</Alert>}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="currentpassword"
              label="Current Password"
              type={this.state.showCurrentPassword ? "text" : "password"}
              id="currentpassword"
              autoComplete="current-password"
              onChange={this.handleChange}
              value={this.state.currentpassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowCurrentPassword}
                    >
                      {this.state.showCurrentPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="newpassword"
              label="New Password"
              type={this.state.showNewPassword ? "text" : "password"}
              id="newpassword"
              autoComplete="new-password"
              onChange={this.handleChange}
              value={this.state.confirmpassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowNewPassword}
                    >
                      {this.state.showNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={this.state.loading}
            >
              Update Password
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  }
}

import React, { Component } from 'react';
import { Link, navigate } from '@reach/router'

import {
    Typography,
    Button,
    TextField,
    IconButton,
    InputAdornment,
    Grid,
    CircularProgress,
 } from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import Alert from '../Alert'

import { AppUser } from '../Auth'

import { Auth } from 'aws-amplify'


export default class SignInForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: ``,
            email: ``,
            password: ``,
            error: ``,
            showPassword: false,
            loading: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleChange = event => {
        if (event.target.name === 'email') {
            this.setState({
                [event.target.name]: event.target.value,
                username: event.target.value,
                error: '',
            })
        }

        this.setState({
            [event.target.name]: event.target.value,
            error: '',
        })
    }

    async handleSubmit(evt) {
        evt.preventDefault();
        const { username, password } = this.state
        const { setUser } = AppUser
        try {
            this.setState({ loading: true })
            await Auth.signIn(username, password)
            const user = await Auth.currentAuthenticatedUser()
            const userInfo = {
                ...user.attributes,
                username: user.username,
            }
            //console.log(userInfo)
            setUser(userInfo)            
            this.setState({ loading: false })
            navigate('/dashboard')
        } catch (error) {
            this.setState({ loading: false, error: error.message })
        }
    }


    render() {
        let { classes } = { ...this.props }

        return (
            <>
            <Typography component="h1" variant="h5" className={classes.title}>
                Sign in
            </Typography>
            {this.state.error && (
                <Alert severity="error">{this.state.error}</Alert>
            )}
            <form className={classes.form} onSubmit={this.handleSubmit} >
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    type="email"
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={this.handleChange}
                    value={this.state.email}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    onChange={this.handleChange}
                    value={this.state.password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                            >
                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={this.state.loading}

                >
                    Sign In
                    {this.state.loading && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                )}
            </Button>
                <Grid container>
                    <Grid item xs align="center"> 
                        <Link to="/forgot-password" variant="body2" className={classes.forgotpass}>
                            Forgot password?
              </Link>
                    </Grid>
                    
                </Grid>
            </form>
            </>
        );
    }
}
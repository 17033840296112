import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import { 
    CssBaseline,
    Box,
    Container,
    Paper,
    Grid
  } from '@material-ui/core';

import Copyright from '../Partials/Copyright';
import UserLayoutHeader from '../Partials/UserLayoutHeader'

import SEO from "../seo"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

export default function UserLayout({ children }) {
    const classes = useStyles();
    return (
      <div className={classes.root}>
          <SEO /> 
              <CssBaseline />
              <UserLayoutHeader  />
              <main className={classes.content}>
                  <div className={classes.appBarSpacer} />
                  <Container maxWidth="lg" className={classes.container}>
                      <Grid container >
                      <Grid item xs={12}>
                              <Paper className={classes.paper}>
      
      {children}</Paper>
                          </Grid>
                      </Grid>
                      <Box pt={4}>
                          <Copyright />
                      </Box>
                  </Container>
              </main>
          </div>
    )
  }
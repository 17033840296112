import React,{ useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import WorklogForm from "../Forms/WorklogForm"
import { getCurrentUser } from '../Auth/AppUser'


const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 2, 2, 0),
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    textField1: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    
  }));
  


export default function Worklog({ props, location }) {
  // console.log("Location details in worklog page",location)
  const classes = useStyles();
  const user = getCurrentUser();
  const [currentUserId, setCurrentUserId] = useState(user['custom:user_id']);

  return (
    <WorklogForm classes={classes} data={location.state} user_id={currentUserId}/>
  );
}
import React, { useState, useEffect, forwardRef } from "react"
import { navigate } from '@reach/router'
import MaterialTable from "material-table"
import Snackbar from '@material-ui/core/Snackbar';
import Moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { 
  Typography,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Grid,
  CircularProgress
} from '@material-ui/core';

import {
  KeyboardDatePicker,DatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';

import VerifyImg from "../../images/verify.svg"

import Alert from '../Alert'
import { 
    API_BASE_URL, 
    taskCategoriesList, 
    taskCountriesList, 
    taskWebsitesList 
} from "../../helpers/functions"


import DateFnsUtils from '@date-io/date-fns';

import { getCurrentUser } from '../Auth/AppUser'

import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  ChevronRight,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronLeft,
  Search,
  ArrowDownward,
  Remove,
  ViewColumn,
} from "@material-ui/icons"

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  submit: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  addnewbutton: {
    margin: '16px auto 0 auto',
    display: 'block',
  },
  banner: {
    backgroundImage: 'linear-gradient(90deg,#eb010f 3%,#bf0a29 77%,#bf0a29 0)',
    borderRadius: '5px',
    padding: '30px 20px',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
        borderRadius:'0',
      },
  },
  usernamewrap:{
    color: '#fff',
    transform:'translate(10px, 0)'
  },
  username:{
    fontSize:'30px'
  },
}));


export default function Dashboard() {
  
  const classes = useStyles();
  const user = getCurrentUser();
  const [currentUserId, setCurrentUserId] = useState(user['custom:user_id']);
  const [currentUserRole, setcurrentUserRole] = useState(user['custom:user_role']);
  const [filterUserId, setfilterUserId] = useState(user['custom:user_id']);

  const [fromDate, setFromDate] = useState(Moment(new Date()).format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(Moment(new Date()).format("YYYY-MM-DD"));
  const [state, setState] = useState({
    columns: [
      { title: "Date", field: "worklog_date" },
      { title: "Task Title", field: "task_title" },
      { title: "Category", field: "worktype_id", lookup: taskCategoriesList },
      { title: "Country", field: "country_id", lookup: taskCountriesList },
      { title: "Website", field: "website_id", lookup: taskWebsitesList },
      { title: "Time Spent", field: "time_spent"  },
    ],
    data: [],
    users: [], 
    totaltime: "00:00", 
    message: ``,
    snackbar_open: false,
    showTable: false,
    loading: true,    
  })

  
  useEffect(() => {
    handleFilter();
    // console.log('test')
    if(currentUserRole === "admin" && state.users.length == 0){
      fetch(`${API_BASE_URL}users`)
      .then(response => response.json()) // parse JSON from request
      .then(data => {
        // console.log("Complete Users: ",data)
        setState(prevState => {
          return { ...prevState, users: data }
        })
      })
    }
  }, [])

  const handleUserChange = (event) => {
    console.log(event)
    setfilterUserId(event.target.value)
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({
      snackbar_open: false,
      message: ``
    });
  };

  const handleFilter = () => {

    setState(prevState => {
      return { ...prevState, loading: true }
    })
    fetch(`${API_BASE_URL}worklogs?from=${fromDate}&to=${toDate}&user_id=${filterUserId}&page=1&per_page=99999`)
      .then(response => response.json())
      .then(data => {
        {data.items.length>0? 
          (
            setState(prevState => {
              return { ...prevState, data: data.items, totaltime: data.totaltime, showTable: true, loading: false }
            })
          ) :
          (
            setState(prevState =>{
              return{...prevState,showTable: false, loading: false}
            })
          )
        }
      })   
  }

  return (
    <>
      {state.message && (
        <Snackbar
          open={state.snackbar_open}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">{state.message}</Alert>
        </Snackbar>
      )}
      <Grid container className={classes.banner}>
        <Grid item xs={8}>
          <Typography component="h2" variant="h6" color="textPrimary" className={classes.usernamewrap}>
              <span >Hello,</span> <div className={classes.username}>{user.name}!</div>
          </Typography>
          <Typography component="h5" variant="h6" color="textPrimary" className={classes.usernamewrap}>
              Manage your timesheets here...
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="flex-start" justify="flex-end" direction="row" >
        {currentUserRole === "admin" && 
        <Grid item xs={12} sm={2} >
          <FormControl>
            <InputLabel id="user-label">User</InputLabel>
            <Select
            labelId="user-label"
            id="user_id"
            name="user_id"											
            value={filterUserId}
            onChange={handleUserChange}
            >
                {state.users.map((user, index) => (
                    <MenuItem value={user.id} key={index}>{user.first_name} {user.last_name}</MenuItem>
                ))}

            </Select>
            </FormControl>
        </Grid>
        }
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12} sm={2} >
            <DatePicker
              id="worklog_from_date"
              name="From Date"
              disableFuture
              openTo="date"
              format="dd/MM/yyyy"
              label="From Date"
              views={["year", "month", "date"]}
              value={fromDate}
              onChange={(date)=>{setFromDate(Moment(date).format('YYYY-MM-DD'))}}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12} md={2} >
            <DatePicker
              id="worklog_to_date"
              name="To Date"
              disableFuture
              openTo="date"
              format="dd/MM/yyyy"
              label="To Date"
              views={["year", "month", "date"]}
              value={toDate}
              // onChange={handleTaskToDateChange}
              onChange={(date)=>{setToDate(Moment(date).format('YYYY-MM-DD'))}}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item xs={12} md={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="medium"
            className={classes.submit}
            onClick={handleFilter}
            >
            Filter Work Logs            
          </Button>
        </Grid>
      </Grid>
      {state.loading === false ? (
        state.showTable ? (
          <>
          <Alert severity="info">Total Hours: <strong>{state.totaltime}</strong></Alert>
          
          <MaterialTable
            title="Manage Your Timesheet"
            columns={state.columns}
            data={state.data}
            icons={tableIcons}
            actions={[
              {
                icon: () => <AddBox />,
                tooltip: "Add Worklog",
                isFreeAction: true,
                onClick: () => {
                  navigate("/worklog")
                },
              },
              {
                icon: () => <Edit />,
                tooltip: "Edit Worklog",
                onClick: (event, rowData) => {
                  navigate("/worklog", { state: rowData })
                  //toggleWorklogForm(rowData);
                },
              },
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSizeOptions: [],
              pageSize: 10,
            }}
            editable={{
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(() => {
                    const requestOptions = {
                      method: "DELETE",
                      headers: { "Content-Type": "application/json" },
                    }

                    fetch(
                      `${API_BASE_URL}worklogs/${oldData.id}`,
                      requestOptions
                    )
                      .then(response => response.json())
                      .then(data => {
                        // console.log(data)
                        setState(prevState => {
                          const data = [...prevState.data]
                          data.splice(data.indexOf(oldData), 1)
                          return { ...prevState, data }
                        })
                      })
                      .catch(error => {
                        alert("There was an error occured during deletion")
                      })

                    resolve()
                  }, 600)
                }),
            }}
          />
          <Alert severity="info">Total Hours: <strong>{state.totaltime}</strong></Alert>
          </>
        ) : (
          <div>
            <Alert severity="warning">
              {fromDate === toDate ? `
              You haven't logged your timesheet for ${(Moment(fromDate).format('DD/MMM/YYYY'))}`
              :
              `You haven't logged your timesheet between ${(Moment(fromDate).format('DD/MMM/YYYY'))} and ${(Moment(toDate).format('DD/MMM/YYYY'))}`
              }
            </Alert>
            <Button 
            size="small"
            className={classes.addnewbutton}
            variant="contained" 
            size="small" 
            color="primary"
            display="block"
            onClick= {() => {navigate("/worklog")}} 
          >
            Add New Work Log
          </Button>
        </div>
        )
      ) : (
        <div className={classes.root}>
          <CircularProgress />
        </div>
      )}
    </>
  )
}

import React from 'react'
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router'
import { AppUser } from '../Auth'
import  GuestLayout  from '../Layout/GuestLayout'


const PublicRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn } = AppUser
    if (isLoggedIn()) {
      return <Redirect to="/dashboard" noThrow />
    }
    //console.log(this.props)
    return (
      <GuestLayout>
        <Component {...rest} />    
      </GuestLayout>
    )
}

PublicRoute.propTypes = {
    component: PropTypes.any.isRequired
  };

export default PublicRoute